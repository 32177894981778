'use strict';

import $ from 'jquery'

$(document).ready(function () {

  var init = function () {
    methods.calcHeights();
    methods.setEvents();
  };

  var methods = {
    setEvents: function () {
      $(document)
        .on('click', '.js-open-info', methods.toggleInfoContainer )
        .on('click', '.js-last', methods.lastPage )
        .on('click', '.js-next', methods.nextPage );
    },

    calcHeights: function () {
      var $container = $('.info-container');

      $('.info-body').each(function (i, el) {
        var $el = $(el);
        $el.css('height', $el.find('.info-body-content').outerHeight());
      });

      $container.each(function (i, el) {
        var $el = $(el);
        var width = $el.find('.info-header').innerWidth() + 4; // TODO check if disableing line break could help
        $el.find('.info-header, .info-body').css('width', width);
      });

      $container.addClass('closed');
      $('.info-body').unwrap();
    },

    lastPage: function () {
      $(this).closest('.info-body')
        .hide()
        .prev()
          .show();
    },

    nextPage: function () {
      $(this).closest('.info-body')
        .hide()
        .next()
          .show();
    },

    toggleInfoContainer: function () {
      var $this = $(this);

      var $container = $this.closest('.info-container')
        .css({'z-index': 10})
        .toggleClass('active')
        .toggleClass('closed');

      $('.info-container')
        .not($container)
          .css({'z-index': 9})
          .addClass('closed')
          .removeClass('active');
    },
  };

  init();

});
